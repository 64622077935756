import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { Area } from 'src/app/models/area.model';
import { AssetStatus, BuildingDetails } from 'src/app/models/building.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { SortByOptions, UserType } from 'src/app/models/customer.model';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import { Region } from 'src/app/models/region.model';
import { PermissionsEnum, SystemComponents } from 'src/app/models/role.model';
import { UserForFilter } from 'src/app/models/user.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AreaService } from 'src/app/services/area.service';
import { BuildingService } from 'src/app/services/building.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss'],
})
export class BuildingsComponent implements OnInit, OnDestroy {
  PermissionsEnum = PermissionsEnum;

  buildings: BuildingDetails[] = [];
  stats: KeyValueItem[] = [];

  mapSortBy: Record<number, string> = {
    [SortByOptions.NEWEST]: 'COMMON.SORT_BY.NEWEST',
    [SortByOptions.SLRN_ASC]: 'COMMON.SORT_BY.SLRN_ASC',
    [SortByOptions.SLRN_DESC]: 'COMMON.SORT_BY.SLRN_DESC',
  };
  //filters
  clicked: boolean = false;
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  status?: number;
  statusOptions = [
    {
      name: 'Pending',
      value: AssetStatus.Pending,
      translation: 'COMMON.ASSET_STATUS.PENDING',
    },
    {
      name: 'Complete',
      value: AssetStatus.Complete,
      translation: 'COMMON.ASSET_STATUS.COMPLETE',
    },
    {
      name: 'Assigned',
      value: AssetStatus.Assigned,
      translation: 'COMMON.ASSET_STATUS.ASSIGNED',
    },
    {
      name: 'Rejected',
      value: AssetStatus.Rejected,
      translation: 'COMMON.ASSET_STATUS.REJECTED',
    },
    {
      name: 'Retagged',
      value: AssetStatus.Retagged,
      translation: 'COMMON.ASSET_STATUS.RETAGGED',
    },
  ];
  tag?: number;
  tagOptions = [
    { name: 'Tagged', value: 0, translation: 'COMMON.TAG.TAGGED' },
    { name: 'Inaccessible', value: 1, translation: 'COMMON.TAG.INACCESSIBLE' },
  ];
  selectedFilters: ChecklistItem[] = [];

  @Input() dateFrom: string;
  @Input() dateTo: string;

  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;

  searchText: string = '';
  searchTextUpdate = new Subject<string>();

  sortByValue: SortByOptions = SortByOptions.NEWEST;
  sortByOptions = SortByOptions;
  filterByValue: string = '';
  sortByName: string = this.mapSortBy[SortByOptions.NEWEST];

  showUpload: boolean = false;
  showDownload: boolean = false;
  downloadBuilding = false;
  downlaodInaccessible = false;
  downloadReplacedMeters = false;

  showSortBy: boolean = false;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private ngUnsubscribe = new Subject<void>();
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  utilityId: number = 0;
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  allCheckboxesChecked: boolean = false;
  dateTypes = [
    {
      name: 'LastUpdateTime',
      value: 'Last Update Time',
      translation: 'COMMON.DATE_TYPE.LAST_UPDATE',
    },
    {
      name: 'TaggedDate',
      value: 'Tagged Date',
      translation: 'COMMON.DATE_TYPE.TAGGED',
    },
    {
      name: 'ValidatedDate',
      value: 'Validated Date',
      translation: 'COMMON.DATE_TYPE.VALIDATED',
    },
  ];
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;

  usersFilter: any; // Stores value of filter
  mobileUsers: UserForFilter[] = []; // Store only mobile users
  adminUsers: UserForFilter[] = []; // Store only admin users
  allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
  filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
  selectedUsers: number[] = [];

  userType?: number;
  userTypeOptions = [
    {
      name: 'First Captured User',
      value: UserType.FirstCapturedUser,
      translation: 'COMMON.USER.TYPE.FIRST_CAPTURED',
    },
    {
      name: 'Last Captured User',
      value: UserType.LastCapturedUser,
      translation: 'COMMON.USER.TYPE.LAST_CAPTURED',
    },
    {
      name: 'Reassigned By',
      value: UserType.ReassignedBy,
      translation: 'COMMON.USER.TYPE.REASSIGNED',
    },
    {
      name: 'Validated By',
      value: UserType.ValidatedBy,
      translation: 'COMMON.USER.TYPE.VALIDATED',
    },
    {
      name: 'Audited By',
      value: UserType.AuditedBy,
      translation: 'COMMON.USER.TYPE.AUDITED',
    },
  ];
  mobileUserType: UserType[] = [
    UserType.FirstCapturedUser,
    UserType.LastCapturedUser,
  ];
  adminUserType: UserType[] = [
    UserType.ValidatedBy,
    UserType.ReassignedBy,
    UserType.AuditedBy,
  ];
  navigationExtras?: NavigationExtras;

  dtId?: number;
  dtName?: string;

  constructor(
    private buildingService: BuildingService,
    private toastr: ToastrService,
    private regionService: RegionService,
    private areaService: AreaService,
    private translationService: TranslationService,
    public route: ActivatedRoute,
    public permissionsService: PermissionsService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private router: Router
  ) {
    this.navigationExtras = this.router.getCurrentNavigation()?.extras;
  }

  ngOnInit(): void {
    if (this.navigationExtras?.state) {
      this.setDtFilter();
    }
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.subscribeToClickEvent();
    this.setFilters();
    this.getRegions();
    this.setCheckedUsers();
    this.getData();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
      if (this.searchText == '') this.search();
    });
    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      this.selectedDateTypeDisplay ?? this.dateTypes[0].translation;
  }
  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }

  private getData(): void {
    this.getBuildingsStats();
    this.reloadTable();
  }

  setDtFilter() {
    if (!localStorage.getItem('filterBuilding')) {
      this.dtId = this.navigationExtras!.state!['dtId'];
      this.dtName = this.navigationExtras!.state!['dtName'];
      this.setFilterStorage();
    }
  }

  private getBuildingsStats() {
    this.buildingService.getBuildingsStats().subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.stats = data.data;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      userType: this.userType
        ? this.selectedUsers.length > 0
          ? this.userType
          : null
        : null,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      dateType: this.selectedDateType,
      status: this.status ? this.status : null,
      tag: this.tag != undefined ? (this.tag == 0 ? false : true) : null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedIds: this.buildings
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
      dtId: this.dtId ? Number(this.dtId) : null,
      dtName: this.dtName ? this.dtName : null,
    };
    return obj;
  }

  async setCheckedUsers() {
    var filter = localStorage.getItem('filterBuilding');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Building') return;
    if (
      filterObject.filter.filterParams.selectedUsers &&
      filterObject.filter.filterParams.userType
    ) {
      this.userType = filterObject.filter.filterParams.userType;
      this.getUsersForFilter(true, filterObject);
    }
  }

  setFilters() {
    var filter = localStorage.getItem('filterBuilding');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Building') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.filterByValue = filterObject.filter.filterParams.filterBy;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.selectedDateType = filterObject.filter.filterParams.dateType;
    this.selectedDateTypeDisplay =
      this.dateTypes.find(
        (type) => type.name == filterObject.filter.filterParams.dateType
      )?.translation ?? this.dateTypes[0].translation;
    this.status = filterObject.filter.filterParams.status;
    this.selectedUsers = filterObject.filter.filterParams.selectedUsers
      ? filterObject.filter.filterParams.selectedUsers
      : [];
    this.tag =
      filterObject.filter.filterParams.tag != null
        ? filterObject.filter.filterParams.tag
          ? 1
          : 0
        : undefined;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
    this.sortByName = this.mapSortBy[this.sortByValue];
    this.dtId = filterObject.filter.filterParams.dtId
      ? filterObject.filter.filterParams.dtId
      : 0;
    this.dtName = filterObject.filter.filterParams.dtName
      ? filterObject.filter.filterParams.dtName
      : undefined;
  }
  private getAll(obj: any) {
    this.buildingService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.buildings = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }
    return true;
  }

  checkAllCheckboxes() {
    this.buildings.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  checkBuilding(id: number) {
    const building = this.buildings.find((x) => x.id === id);
    if (building) {
      building.isChecked = !building.isChecked;
      this.areAllCheckboxesChecked();
    }
  }

  areAllCheckboxesChecked() {
    this.allCheckboxesChecked = this.buildings.every((x) => x.isChecked);
  }

  onFilterBy(option: any) {
    this.filterByValue = option;
    this.reloadTable(1);
  }

  reloadTable(page: any = null) {
    this.allCheckboxesChecked = false;
    if (page) this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
    };

    this.analyticsService.logCustomEvent('BUILDINGS: Filter list', {
      page: obj.pageInfo.page,
      sort_by: obj.filterParams.sortBy,
      search: obj.filterParams.search,
      filter_card: obj.filterParams.filterBy,
      user_type: obj.filterParams.userType,
      date_from: obj.filterParams.dateFrom,
      date_to: obj.filterParams.dateTo,
      users: obj.filterParams.selectedUsers,
      date_type: obj.filterParams.dateType,
      status: obj.filterParams.status,
      tag: obj.filterParams.tag,
      areas: obj.filterParams.selectedAreas,
      dtId: obj.filterParams.dtId,
    });

    this.getAll(obj);
  }

  search() {
    this.reloadTable(1);
  }
  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };

    // Remove 'T23:59:59' from the dateTo to avoid errors
    if (obj.filterParams.dateTo) {
      obj.filterParams.dateTo = obj.filterParams.dateTo.replace(
        'T23:59:59',
        ''
      );
    }

    localStorage.setItem('cameFrom', 'Building');
    localStorage.setItem(
      'filterBuilding',
      JSON.stringify({ type: 'Building', filter: obj })
    );
  }
  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByName = this.mapSortBy[option];
    this.reloadTable();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
        this.showSortBy = false;
        this.showSelectDateType = false;
      });
  }

  download() {
    if (this.downloadBuilding) {
      this.downloadBuildings();
    } else if (this.downlaodInaccessible) {
      this.downloadInaccessibles();
    } else if (this.downloadReplacedMeters) {
      this.downloadReplacedMeter();
    }
  }
  downloadReplacedMeter() {
    let obj = this.getFilterObject();

    this.analyticsService.logCustomEvent('BUILDINGS: Download Replaced SLRN', {
      sort_by: obj.sortBy,
      search: obj.search,
      filter_card: obj.filterBy,
      user_type: obj.userType,
      date_from: obj.dateFrom,
      date_to: obj.dateTo,
      users: obj.selectedUsers,
      date_type: obj.dateType,
      status: obj.status,
      tag: obj.tag,
      areas: obj.selectedAreas,
      dtId: obj.dtId,
    });

    this.buildingService.downloadReplacedSlrn(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  downloadInaccessibles() {
    let obj = this.getFilterObject();

    this.analyticsService.logCustomEvent(
      'BUILDINGS: Download Inaccessible Meters',
      {
        sort_by: obj.sortBy,
        search: obj.search,
        filter_card: obj.filterBy,
        user_type: obj.userType,
        date_from: obj.dateFrom,
        date_to: obj.dateTo,
        users: obj.selectedUsers,
        date_type: obj.dateType,
        status: obj.status,
        tag: obj.tag,
        areas: obj.selectedAreas,
      }
    );

    this.buildingService.downloadInaccessible(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  downloadBuildings() {
    let obj = this.getFilterObject();

    this.analyticsService.logCustomEvent(
      'BUILDINGS: Download Building Information',
      {
        sort_by: obj.sortBy,
        search: obj.search,
        filter_card: obj.filterBy,
        user_type: obj.userType,
        date_from: obj.dateFrom,
        date_to: obj.dateTo,
        users: obj.selectedUsers,
        date_type: obj.dateType,
        status: obj.status,
        tag: obj.tag,
        areas: obj.selectedAreas,
        dtId: obj.dtId,
      }
    );

    this.buildingService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  selectStatus(e: any) {
    if (e.target.value != '') {
      this.status = e.target.value;
    } else {
      this.status = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });

    this.createFilterCards();
  }

  selectArea(e: any) {
    this.createFilterCards();
  }

  selectTag(e: any) {
    if (e.target.value != '') {
      this.tag = e.target.value;
    } else {
      this.tag = undefined;
    }
    this.createFilterCards();
    this.reloadTable(1);
  }

  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == 'User') {
      this.allUsers.forEach((user) => {
        if (user.name === item.selectedValue) {
          user.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'User type') {
      this.userType = undefined;
      this.allUsers.forEach((user) => {
        // Clear list if he selects same filter again to get all cleared
        user.checked = false;
      });
      this.allUsers = this.filteredUsers = [...[]];
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Status') {
      this.status = undefined;
    } else if (item.property == 'Tag') {
      this.tag = undefined;
    } else if (item.property == 'Dt Name') {
      this.dtId = 0;
      this.dtName = '';
    }

    this.createFilterCards();

    if (this.selectedFilters.length == 0) {
      this.regions.forEach((region) => {
        region.checked = false;
      });
      this.reloadTable(1);
    }
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = this.filteredUsers = [...[]];

    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];

    this.selectedAreas = [];
    this.selectedRegions = [];

    this.userType = undefined;
    this.status = undefined;
    this.tag = undefined;

    this.clicked = !this.clicked;

    this.dtId = undefined;
    this.dtName = undefined;

    this.reloadTable(1);
  }

  createFilterCards() {
    this.selectedFilters = [];

    let selectedUsers = this.allUsers.filter((x) => x.checked);
    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedUsers = this.allUsers
      .filter((x) => x.checked)
      .map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(
          this.utName,
          selectedAreas[i].name,
          'COMMON.UT.SINGLE'
        )
      );
    }

    if (this.userType) {
      var userTypeName = UserType[this.userType!];
      var userTypeTranslationKey = this.userTypeOptions.find(
        (option) => option.value == this.userType
      )?.translation;
      this.selectedFilters.push(
        new ChecklistItem(
          'User type',
          userTypeName,
          'COMMON.USER.TYPE.TITLE',
          userTypeTranslationKey
        )
      );
    }

    //users
    for (var i = 0; i < selectedUsers.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem('User', selectedUsers[i].name, 'COMMON.USER.SINGLE')
      );
    }

    //status
    if (this.status) {
      var statusName = AssetStatus[this.status!];
      var statusTranslationKey = this.statusOptions.find(
        (option) => option.value == this.status
      )?.translation;
      this.selectedFilters.push(
        new ChecklistItem(
          'Status',
          statusName,
          'COMMON.STATUS',
          statusTranslationKey
        )
      );
    }

    //tag
    if (this.tag != undefined) {
      var tagName = this.tagOptions[this.tag].name;
      var tagTranslationKey = this.tagOptions[this.tag].translation;
      this.selectedFilters.push(
        new ChecklistItem('Tag', tagName, 'COMMON.TAG.TITLE', tagTranslationKey)
      );
    }

    if (this.dtId && this.dtName) {
      //dt from transformers
      this.selectedFilters.push(
        new ChecklistItem('Dt Name', this.dtName, 'COMMON.DT_NAME')
      );
    }
  }
  setCheckedAreas() {
    var filter = localStorage.getItem('filterBuilding');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Building') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  setCheckedRegions() {
    var filter = localStorage.getItem('filterBuilding');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Building') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  removeFilterStorage() {
    if (this.allAreas.length > 0 && this.regions.length > 0)
      localStorage.removeItem('filterBuilding');
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.translation;
    this.showSelectDateType = false;
  }

  resetUserList() {
    this.allUsers.forEach((user) => {
      user.checked = false;
    });
    this.allUsers = [];
  }

  selectUserType(e: any) {
    if (this.userType) this.resetUserList();

    if (e.target.value != '') {
      this.userType = e.target.value;
      this.getUsersForFilter(false);
    } else {
      this.userType = undefined;
    }

    this.createFilterCards();
  }

  getUsersForFilter(isFromCache: boolean, filterObject?: any) {
    if (
      this.userType == UserType.FirstCapturedUser ||
      this.userType == UserType.LastCapturedUser
    ) {
      // Dobavi mobilne usere iz baze ako nisi
      if (this.mobileUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Mobile)
          .subscribe((resp) => {
            this.mobileUsers = resp.data;
            this.allUsers = this.filteredUsers = this.mobileUsers;
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
            }
            this.createFilterCards();
          });
      } else {
        this.allUsers = this.filteredUsers = [...this.mobileUsers];
      }
    } else {
      let permission = PermissionsEnum.Building_Assign;

      switch (Number(this.userType)) {
        case UserType.ValidatedBy:
          permission = PermissionsEnum.Building_ApproveReject;
          break;
        case UserType.AuditedBy:
          permission = PermissionsEnum.Building_AuditReview;
          break;
      }

      if (this.adminUsers.length === 0) {
        this.userService
          .getUsersForFilter(SystemComponents.Admin)
          .subscribe((resp) => {
            this.adminUsers = resp.data;
            this.allUsers = this.filteredUsers = this.adminUsers.filter((x) =>
              x.permissions?.some((x) => Number(x) == permission)
            );
            if (isFromCache) {
              this.selectedUsers =
                filterObject.filter.filterParams.selectedUsers;
              this.allUsers.forEach((element) => {
                if (this.selectedUsers.includes(element.id))
                  element.checked = true;
                else element.checked = false;
              });
              this.createFilterCards();
            }
          });
      } else {
        this.allUsers = this.filteredUsers = [
          ...this.adminUsers.filter((x) =>
            x.permissions?.some((x) => Number(x) == permission)
          ),
        ];
      }
    }
  }

  applySearchFilter(e: any) {
    this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
  }

  selectUsers(e: any) {
    if (e.status) {
      this.allUsers.push(e.value);
    } else {
      var index = this.allUsers.indexOf(e.value);
      this.allUsers.splice(index, 1);
    }
    this.createFilterCards();
  }

  applyFilter(item: ChecklistItem) {
    this.reloadTable(1);
  }
}
