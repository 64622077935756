import { Area } from './area.model';
import { Slrn } from './building.model';
import { History } from './customer.model';
import { Feeder, FeederType } from './feeder.model';
import { Feeder11 } from './feeder11.model';
import { Feeder33 } from './feeder33.model';
import { HistoryOutput } from './history.model';
import { Image } from './image.model';
import { KeyValuePair } from './keyValueItem.model';
import { getInitMeter, Meter } from './meter.model';

export interface Dt {
  id: number;
  name: string;
  number: string;
  connectedFeederNumber: string;
  feeder11?: Feeder11;
  feeder33?: Feeder33;
}

export interface DtCustomerCreate {
  externalId: number;
  name: string;
  number: string;
  connectedFeederNumber: string;
  feeder11?: Feeder11;
  feeder33?: Feeder33;
}
export function getInitDtCustomer(): DtCustomerCreate {
  return {
    externalId: 0,
    name: '',
    number: '',
    connectedFeederNumber: '',
    feeder11: {
      id: 0,
      name: '',
      number: '',
      substation: '',
    },
  };
}
export function getInitDt(): Dt {
  return {
    id: 0,
    name: '',
    number: '',
    connectedFeederNumber: '',
    feeder11: {
      id: 0,
      name: '',
      number: '',
      substation: '',
    },
  };
}

export function getInitDtDto(): DtDto {
  return {
    id: 0,
    name: '',
    number: '',
    area: new Area(),
    feeder: {
      id: 0,
      name: '',
      number: '',
      feederType: FeederType.NOT_SELECTED,
    },
    latitude: 0,
    longitude: 0,
  };
}

export interface DtEdit extends Dt {
  area: Area;
  meter?: Meter;
  slrn?: string;
  kvaRating?: string;
  locationAddress?: string;
  locationLandmark?: string;
  type: DTType;
  dtStatus: DTStatus;
  status?: string;
  guid?: string; 
  mountPosition: DTMountPosition;
  fencingStatus: DTFencingStatus;
  dateOfCommissioning: Date;
  dateOfManufacturer: Date;
  serviceCentre?: string;
  assignedUserId?: number;
  assignedUser: string;
  feederPointCondition: FeederPointCondition;
  feederPillarCondition: FeederCondition;
  upriserCableCondition: [];
  subFeederPillarCondition: FeederCondition;
  feederPillar: FeederAvailability;
  subFeederPillar: FeederAvailability;
  numberOfUpriserCable: number;
  images: Image[];
  tapPositioning?: string;
  impedancePercentage?: number;
  customersCount?: number;
  buildingsCount?: number;
  polesCount?: number;
  longitude?: number;
  latitude?: number;
  history: HistoryOutput;
  dataQualityId: number;
  dataQualityStatus: string;

  firstCapturedUser: string;
  lastUpdatedUser: string;
  creationDate: string;
  updatedDate: string;
  reassignedBy: string;
  validatedBy: string;
  validatedDate: string;
  auditedStatus: string;
  auditedBy: string;
  auditedDate: string;
  serialNumber: string;

  postCode?: string;
  postCodeException?: string;
  manufacturerName: string;
  conductorSize?: string;
  dtHistories?: History[];
  comment: string;
  dataValidationReview: KeyValuePair[];
}

export function getInitDtEdit(): DtEdit {
  return {
    id: 0,
    slrn: '',
    name: '',
    number: '',
    connectedFeederNumber: '',
    area: new Area(),
    meter: getInitMeter(),
    locationLandmark: '',
    locationAddress: '',
    type: DTType.Private,
    dtStatus: DTStatus.Decommissioned,
    status: '',
    mountPosition: DTMountPosition.Ground,
    fencingStatus: DTFencingStatus.Compact,
    dateOfCommissioning: new Date(),
    dateOfManufacturer: new Date(),
    serviceCentre: '',
    tapPositioning: '',
    impedancePercentage: 0,
    assignedUserId: 0,
    assignedUser: '',
    customersCount: 0,
    buildingsCount: 0,
    polesCount: 0,
    feederPointCondition: FeederPointCondition.Bad,
    feederPillarCondition: FeederCondition.BadCleanUp,
    upriserCableCondition: [],
    subFeederPillarCondition: FeederCondition.BadCleanUp,
    feederPillar: FeederAvailability.Available,
    subFeederPillar: FeederAvailability.Available,
    numberOfUpriserCable: 0,
    images: [],
    longitude: 0,
    latitude: 0,
    history: new HistoryOutput(),
    dataQualityId: 0,
    dataQualityStatus: '',
    firstCapturedUser: '',
    lastUpdatedUser: '',
    creationDate: '',
    updatedDate: '',
    reassignedBy: '',
    validatedBy: '',
    validatedDate: '',
    auditedStatus: '',
    auditedBy: '',
    auditedDate: '',
    serialNumber: '',
    manufacturerName: '',
    comment: '',
    dataValidationReview: [],
  };
}

export class DtDto {
  id: number;
  name: string;
  number: string;
  area: Area;
  feeder: Feeder;
  latitude: number;
  longitude: number;

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.name = data?.name ?? '';
    this.number = data?.number ?? '';
    this.area = new Area(data?.area);
    this.feeder = new Feeder(data?.feeder);
    this.latitude = data?.latitude ?? 0;
    this.longitude = data?.longitude ?? 0;
  }
}
export interface DtSearch {
  id: number;
  name: string;
  slrn: string;
}
export interface DtCrud {
  id: number;
  name: string;
  number: string;
  area: Area;
  feeder: Feeder;
  feederType: FeederType;
  history: HistoryOutput;
  utilityId: number;
}
export enum FeederPointCondition {
  Good = 1,
  Bad = 2,
}
export enum FeederCondition {
  Good = 1,
  BadCleanUp = 2,
  BadReplacement = 3,
  Burnt = 4,
}
export enum FeederAvailability {
  Available = 1,
  NotAvailable = 2,
}
export enum DTType {
  Private = 1,
  Public = 2,
}
export enum DTMountPosition {
  Ground = 1,
  Pole = 2,
}
export enum DTFencingStatus {
  NoFence = 1,
  Fenced = 2,
  Compact = 3,
}

export enum DTStatus {
  Active = 1,
  Inactive = 2,
  Suspended = 3,
  Decommissioned = 4,
}
export enum AssetStatus {
  Pending = 0,
  Complete = 1,
  Incomplete = 2,
  Assigned = 3,
  Rejected = 4,
}
export interface DTDetails {
  id: number; // ok
  slrn: Slrn;
  number: string;
  name: string; // ok
  kvaRating: string;
  locationAddress: string;
  type: DTType;
  dtStatus: string;
  status: string;
  dataQualityStatus: string;
  mountPosition: DTMountPosition; // ok
  tag: string;
  area: DTArea;
  feeder: DTFeeder;
  connectedCustomers: number;
  isChecked: boolean;
}
export interface DTArea {
  id: number;
  name: string;
  regionId: number;
  regionName: string;
}
export interface DTFeeder {
  feederType: string;
  id: number;
  name: string;
}
export interface DTHeader {
  id: number; // ok
  slrn: string;
  number: string;
  name: string; // ok
  dtStatus: number;
  status: string;
  locationAddress: string;
}

export function initDTHeader(): DTHeader {
  return {
    id: 0,
    name: '',
    number: '',
    slrn: '',
    dtStatus: 0,
    status: '',
    locationAddress: '',
  };
}

export function initDtSearch(): DtSearch {
  return {
    id: 0,
    name: '',
    slrn: '',
  };
}

export interface DtCrudCreate {
  id: number;
  name: string;
  number: string;
  areaId: number;
  feederId: number;
  feederType: FeederType;
  history?: HistoryOutput;
  utilityId?: number;
}

export class DtViewSave {
  id: number = 0;
  name: string = '';
  number: string = '';
  areaId: number = 0;
  feederId: number = 0;
  feederType: FeederType = -1;
  locationLandmark: string = '';
  locationAddress: string = '';
  assignedUserId: number = 0;
  fencingStatus: number = -1;
  kvaRating?: string = '';
  type?: number = -1;
  mountPosition?: number = -1;
  dtStatus?: number = -1;
  manufacturerName: string = '';
  serialNumber: string = '';
  dateOfCommissioning?: Date = undefined;
  dateOfManufacturer?: Date = undefined;
  history?: HistoryOutput;
  meter?: Meter;
  impedancePercentage?: number;
}

export function mapDtEditToViewSave(dt: DtEdit): DtViewSave {
  return {
    id: dt.id,
    name: dt.name,
    number: dt.number,
    areaId: dt.area.id,
    locationLandmark: dt.locationLandmark || '',
    locationAddress: dt.locationAddress || '',
    assignedUserId: dt.assignedUserId || 0,
    kvaRating: dt.kvaRating || '',
    type: dt.type,
    mountPosition: dt.mountPosition,
    dtStatus: dt.dtStatus || -1,
    dateOfCommissioning: dt.dateOfCommissioning,
    dateOfManufacturer: dt.dateOfManufacturer,
    manufacturerName: dt.manufacturerName,
    serialNumber: dt.serialNumber,
    fencingStatus: dt.fencingStatus || -1,
    feederId: (dt.feeder11 ? dt.feeder11.id : dt.feeder33?.id) || 0,
    feederType: dt.feeder11 ? FeederType.KV11 : FeederType.KV33,
    history: dt.history,
    meter: dt.meter,
    impedancePercentage:
      dt.impedancePercentage != null || dt.impedancePercentage != undefined
        ? +dt.impedancePercentage
        : undefined,
  };
}

export class DtSave {
  id: number = 0;
  name: string = '';
  number: string = '';
  areaId: number = 0;
  feederId: number = 0;
  feederType: FeederType = 0;
}

export function initDtCrud(): DtCrud {
  return {
    id: 0,
    name: '',
    number: '',
    area: new Area(),
    feeder: new Feeder(),
    feederType: FeederType.NOT_SELECTED,
    history: new HistoryOutput(),
    utilityId: 0,
  };
}

export function mapObjToCreateObj(dt: DtCrud): DtCrudCreate {
  return {
    id: dt.id,
    name: dt.name,
    number: dt.number,
    areaId: dt.area.id,
    feederId: dt.feeder.id,
    feederType: dt.feeder.feederType,
    history: dt.history,
    utilityId: dt.utilityId,
  };
}

export class KvaOption {
  value: string = '';
  viewValue: string = '';
}
export interface DtImage {
  url: string;
  imageType: any;
}

export class DtStats {
  count: number;
  statType: DtStatsOptions;
}
export enum DtStatsOptions {
  Total,
  AvgCustomerCount,
  Retagged,
  AvgPoleCount,
}
