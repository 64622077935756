export class Role {
  id: number = 0;
  name: string = '';
  systemComponent: SystemComponents = -1;
  permissions: PermissionsEnum[] = [];
  isDisabled: boolean;

  constructor(data?: any) {
    this.id = data?.id || 0;
    this.name = data?.name || '';
    this.systemComponent = data?.systemComponent || SystemComponents.Undefined;
    this.permissions = data?.permissions || [];
    this.isDisabled = data?.isDisabled || false;
  }
}
export enum SystemComponents {
  Undefined = -1,
  Admin = 0,
  Mobile = 1,
}

export enum PermissionsEnum {
  Customer_View = 1,
  Customer_CreateEdit = 2,
  Customer_Delete = 3,
  Customer_Assign = 4,
  Customer_ApproveReject = 5,
  Customer_AuditReview = 6,
  Customer_RecallAssigned = 7,
  Customer_ViewCrud = 8,
  Customer_CreateEditCrud = 9,
  Customer_Retag = 10,
  Customer_RecallValidated = 11,
  Customer_Regularization = 12,
  Customer_AssignForDecommission = 13,
  Customer_Unvisited = 14,
  Customer_NewEntryBreakdown = 15,

  Transformers_View = 101,
  Transformers_CreateEdit = 102,
  Transformers_Delete = 103,
  Transformers_Assign = 104,
  Transformers_ApproveReject = 105,
  Transformers_AuditReview = 106,
  Transformers_RecallAssigned = 107,
  Transformers_ViewCrud = 108,
  Transformers_CreateEditCrud = 109,
  Transformers_Retag = 110,
  Transformers_RecallValidated = 111,

  Pole_View = 201,
  Pole_CreateEdit = 202,
  Pole_Delete = 203,
  Pole_Assign = 204,
  Pole_ApproveReject = 205,
  Pole_AuditReview = 206,
  Pole_RecallAssigned = 207,
  Pole_Retag = 208,
  Pole_RecallValidated = 209,

  Building_View = 301,
  Building_CreateEdit = 302,
  Building_Delete = 303,
  Building_Assign = 304,
  Building_ApproveReject = 305,
  Building_AuditReview = 306,
  Building_RecallAssigned = 307,
  Building_Retag = 308,
  Building_RecallValidated = 309,

  Meters_View = 401,
  Meters_CreateEdit = 402,
  Meters_Delete = 403,
  Meters_ApproveReject = 404,
  Meters_Retag = 406,

  Survey_View = 501,
  Survey_CreateEdit = 502,
  Survey_Delete = 503,
  Survey_ApproveReject = 504,
  Installation_View = 601,
  Installation_CreateEdit = 602,
  Installation_Delete = 603,
  Installation_ApproveReject = 604,
  UserManagement_View = 701,
  UserManagement_CreateEdit = 702,
  UserManagement_Delete = 703,
  UserManagement_BulkAssign = 704,
  RoleManagement_View = 801,
  RoleManagement_CreateEdit = 802,
  RoleManagement_Delete = 803,
  Ts_View = 901,
  Ts_CreateEdit = 902,
  Ts_Delete = 903,
  F33_View = 1001,
  F33_CreateEdit = 1002,
  F33_Delete = 1003,
  Ss_View = 1101,
  Ss_CreateEdit = 1102,
  Ss_Delete = 1103,
  F11_View = 1201,
  F11_CreateEdit = 1202,
  F11_Delete = 1203,
  Area_View = 1301,
  Area_CreateEdit = 1302,
  Area_Delete = 1303,
  Region_View = 1401,
  Region_CreateEdit = 1402,
  Region_Delete = 1403,
  MeterType_View = 1501,
  MeterType_CreateEdit = 1502,
  MeterType_Delete = 1503,
  AssetRating_View = 1601,
  AssetRating_CreateEdit = 1602,
  AssetRating_Delete = 1603,
  AssetStatus_View = 1701,
  AssetStatus_CreateEdit = 1702,
  AssetStatus_Delete = 1703,
  DtMeterType_View = 1801,
  DtMeterType_CreateEdit = 1802,
  DtMeterType_Delete = 1803,
  Tariff_View = 1901,
  Tariff_CreateEdit = 1902,
  Tariff_Delete = 1903,
  AuditLog_View = 2001,
  AuditLog_Download = 2002,
  MeterPrice_View = 2101,
  MeterPrice_CreateEdit = 2102,
  MeterPrice_Delete = 2103,

  Assignment = 3001,

  AuditStatus = 4001,
  ValidationStatus = 4002,
  SiteVerification = 5001,
  Maintenance = 5002,

  Slt_View = 6001,
  Slt_CreateEdit = 6002,
  Slt_Delete = 6003,

  AdoraWorkOrder = 7001,
  Organization_Create = 8001,

  MeterStockInventory_View = 9001,
  MeterStockInventory_Create = 9002,

  MeterStockUtilization_View = 9003,

  DecommissionedMeterStock_View = 9004,
  DecommissionedMeterStock_Approve = 9005,
  DecommissionedMeterStock_Edit = 9006,

  MeterReading = 10000,

  BillingAccounts_View = 11000,
  BillingAccounts_CreateEdit = 11001,
  BillingAccounts_Delete = 11002,

  Report_Misaligned = 12000,
  Report_DataQualityRejection = 12001,
  Report_Regularization = 12002,
}
