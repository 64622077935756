<div class="container-fluid regular-tab-content">
  <div class="green-table">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="d-flex">
        <div class="search-input">
          <img
            src="../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            class="smaller-font"
            type="text"
            placeholder="Search"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>
        <div class="search-type-picker">
          <div
            class="dropdown smaller-font"
            (click)="showSelectSearchType = !showSelectSearchType"
            click-stop-propagation
          >
            <span class="label smaller-font"
              >{{ "COMMON.BY" | translate }}:</span
            >
            <span>{{ selectedSearchTypeDisplay }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" *ngIf="showSelectSearchType">
            <div
              (click)="selectSearchType(searchType)"
              *ngFor="let searchType of searchByOptions"
            >
              <div>{{ searchType.value }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datetype-picker">
              <div
                class="dropdown smaller-font"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span class="smaller-font"
                  >{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span
                >
                <span>{{ selectedDateTypeDisplay }}</span>
                <i class="arrow-custom --small down"></i>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes"
                >
                  <div>{{ dateType.value }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateFrom">
                {{ "COMMON.FROM" | translate }}:
              </label>
              <input
                #fromPicker
                onclick="this.showPicker()"
                placeholder="dd/mm/yyyy"
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>

            <div class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateTo">
                {{ "COMMON.TO" | translate }}:
              </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>
          </div>
        </div>
        <div
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown smaller-font">
            <span class="smaller-font"
              >{{ "COMMON.SORT_BY.TITLE" | translate }}:</span
            >
            <span>{{ sortByLabelValue.label }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div (click)="sortBy(sortByOptions.NEWEST)">
              <div>{{ "COMMON.SORT_BY.NEWEST" | translate }}</div>
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_ASC)">
              <div>{{ "COMMON.SLRN" | translate }}</div>
              <img
                class="arrow-up"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow icon"
              />
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_DESC)">
              <div>{{ "COMMON.SLRN" | translate }}</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
            <div (click)="sortBy(sortByOptions.ACCNO_ASC)">
              <div>{{ "COMMON.SORT_BY.ACCOUNT_NO" | translate }}</div>
              <img
                class="arrow-up"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow icon"
              />
            </div>
            <div (click)="sortBy(sortByOptions.ACCNO_DESC)">
              <div>{{ "COMMON.SORT_BY.ACCOUNT_NO" | translate }}</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
          </div>
        </div>
        <button class="button primary--white four-radius" (click)="download()">
          <span class="smaller-font">{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        <span class="label smaller-font">
          {{ "COMMON.FILTER" | translate }}</span
        >
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="regions"
          [label]="buName + 's'"
          [borderRadius]="'0.4rem'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
          (applyFilterEmitter)="reloadTable(1)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          class="multiselect smaller-font"
          [clicked]="clicked"
          [items]="areas"
          [label]="utName + 's'"
          [borderRadius]="'0.4rem'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
          (applyFilterEmitter)="reloadTable(1)"
        >
        </app-multi-select-dropdown>
        <select
          name="tariff-select"
          (change)="selectTariff($event)"
          class="form-select"
        >
          <option value="0" [selected]="tariff == 0" disabled>
            {{ "COMMON.TARIFF.SINGLE" | translate }}
          </option>
          <option
            [selected]="tariff == o.id"
            *ngFor="let o of tariffs"
            value="{{ o.id }}"
          >
            {{ o.name }}
          </option>
        </select>

        <select
          *ngIf="isServiceTypeFieldVisible"
          name="st-select"
          (change)="selectUserType($event)"
          class="form-select"
        >
          <option value="" [selected]="!userType" disabled>
            {{ "COMMON.USER.TYPE.TITLE" | translate }}
          </option>
          <option
            [selected]="userType == o.value"
            *ngFor="let o of userTypeOptions"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>

        <app-multi-select-dropdown
          class="multiselect"
          [searchFilterActive]="true"
          [borderRadius]="'0.4rem'"
          (filterValue)="applySearchFilter($event)"
          [clicked]="clicked"
          [items]="filteredUsers"
          [label]="'Users'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectUsers($event)"
          (applyFilterEmitter)="reloadTable(1)"
        >
        </app-multi-select-dropdown>
        <select
          name="tag-select"
          (change)="selectTag($event)"
          class="form-select"
        >
          <option value="" [selected]="!tag" disabled>
            {{ "COMMON.TAG.TITLE" | translate }}
          </option>
          <option
            [selected]="tag == t.value"
            *ngFor="let t of tagOptions"
            value="{{ t.value }}"
          >
            {{ t.name }}
          </option>
        </select>
        <select
          name="slt-select"
          (change)="selectSltStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!sltStatus" disabled>
            {{ sltStatusName }}
          </option>
          <option
            [selected]="sltStatus == s.key"
            *ngFor="let s of sltOptions"
            value="{{ s.key }}"
          >
            {{ s.value }}
          </option>
        </select>

        <select
          name="uop-select"
          (change)="selectUseOfPremises($event)"
          class="form-select"
        >
          <option value="" [selected]="!useOfPremises" disabled>
            {{ "COMMON.USE_OF_PREMISES" | translate }}
          </option>
          <option
            [selected]="useOfPremises == u.value"
            *ngFor="let u of uopOptions"
            value="{{ u.value }}"
          >
            {{ u.name }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
          [filters]="selectedFilters"
          (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)"
        >
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="customers">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th class="checkbox">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th>{{ "COMMON.SLRN" | translate }}</th>
              <th>{{ "COMMON.TABLE_HEADERS.BUILDING_OWNER" | translate }}</th>
              <th>{{ "COMMON.TABLE_HEADERS.ACCT_NO" | translate }}</th>
              <th>{{ "COMMON.TABLE_HEADERS.METER_NO" | translate }}</th>
              <th>{{ "COMMON.DT_NAME" | translate }}</th>
              <th>{{ "COMMON.ADDRESS" | translate }}</th>
              <th>{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th>{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th>{{ "COMMON.TARIFF.SINGLE" | translate }}</th>
              <th>{{ "COMMON.VALIDATION_STATUS" | translate }}</th>
              <th>{{ "COMMON.TAG.TITLE" | translate }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let customer of customers; let i = index"
              (click)="selectDataQuality(customer.id, customer.isOnBoard)"
            >
              <td>
                <input
                  type="checkbox"
                  (click)="checkCustomer(customer.id)"
                  class="form-check-input"
                  [ngModel]="customer.isChecked"
                />
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.slrn | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.buildingOwner | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.accountNumber | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.meterNo | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.dtName | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.physicalAddress | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.area.regionName | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.area.name | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ customer.tariffName | hasValue }}
              </td>
              <td>
                <div
                  *ngIf="
                    !customer.dqStatus &&
                    customer.assetStatus !== AssetStatus.Assigned
                  "
                  class="gray-status v-2"
                >
                  {{ "COMMON.ASSET_STATUS.PENDING" | translate }}
                </div>
                <div
                  *ngIf="customer.assetStatus === AssetStatus.Assigned"
                  class="orange-status v-2"
                >
                  {{ "COMMON.ASSET_STATUS.ASSIGNED" | translate }}
                </div>
                <div
                  *ngIf="
                    customer.dqStatus &&
                    customer.dqStatus?.toString() == 'Approved' &&
                    customer.assetStatus !== AssetStatus.Assigned
                  "
                  class="green-status v-2"
                >
                  {{ "COMMON.ASSET_STATUS.COMPLETE" | translate }}
                </div>
                <div
                  *ngIf="
                    customer.dqStatus &&
                    customer.dqStatus?.toString() == 'Rejected' &&
                    customer.assetStatus !== AssetStatus.Assigned
                  "
                  class="dark-red-status v-2"
                >
                  {{ "COMMON.ASSET_STATUS.REJECTED" | translate }}
                </div>
              </td>
              <td>
                <div *ngIf="!customer.isOnBoard">
                  <div *ngIf="!customer.isNew" class="dark-green-status v-2">
                    {{ "COMMON.TAG.EXISTING" | translate }}
                  </div>
                  <div *ngIf="customer.isNew" class="green-status v-2">
                    {{ "COMMON.TAG.NEW_ENTRY" | translate }}
                  </div>
                </div>
                <div *ngIf="customer.isOnBoard">
                  <div class="olive-green-status v-2">
                    {{ "COMMON.TAG.ONBOARD" | translate }}
                  </div>
                </div>
              </td>
              <td class="td-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-bs-toggle="modal"
                  data-bs-target="#history"
                  (click)="viewHistory(customer.id)"
                  width="22"
                  height="22"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
                  />
                </svg>
              </td>
              <td (click)="setFilterStorage()" class="more-icon">
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <app-paging
        *ngIf="count > 0"
        [pageSize]="pageSize"
        [useDirect]="true"
        [count]="count"
        [currentPage]="currentPage"
        (pageChange)="pageChange($event)"
      ></app-paging>
    </div>
  </div>
</div>
